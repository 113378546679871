import type {PropsWithChildren} from 'react'

interface PanelProps {
  className?: string
}

export function Panel({children, className = ''}: PropsWithChildren<PanelProps>) {
  return <div className={`border rounded-2 color-border-default d-flex flex-column ${className}`}>{children}</div>
}

try{ Panel.displayName ||= 'Panel' } catch {}